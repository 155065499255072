<script>
import moment from 'moment'
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { relateTypeList } from '@/utils/textFile'
export default {
  name: 'bannerActivity',
  data() {
    return {
      ...api.command.getState(),
      showTypeList: [
        {
          name: '首页',
          text: '首页',
          value: '1'
        },
        {
          name: '商城',
          text: '商城',
          value: '2'
        },
        {
          name: '专题页',
          text: '专题页',
          value: '3'
        },
        {
          name: '景点门票',
          text: '景点门票',
          value: '4'
        },
        {
          name: '领券中心',
          text: '领券中心',
          value: '5'
        }
      ]
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/market/farmBanner/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input'
        },
        {
          label: '',
          key: '',
          type: 'text'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'name',
          title: '名称',
          align: 'left',
          customRender: (text, records) => {
            const src = records.imgUrl.split(',')[0]
            return (
              <div class="product-order-item-copy">
                <img src={src} />
                <div>{text}</div>
              </div>
            )
          },
          sorter: (a, b) => a.name.length - b.name.length
        },
        {
          dataIndex: 'showType',
          title: '展示位置',
          align: 'left',
          customRender: records => {
            if (records) {
              let brr = records.split(',')
              let crr = []
              this.showTypeList.forEach((e, index) => {
                if (brr.includes(e.value)) {
                  crr.push(e.name)
                }
              })
              return crr.join('、')
            } else {
              return ''
            }
          },
          onExport: records => {
            if (records) {
              let brr = records.split(',')
              let crr = []
              this.showTypeList.forEach((e, index) => {
                if (brr.includes(e.value)) {
                  crr.push(e.name)
                }
              })
              return crr.join('、')
            } else {
              return ''
            }
          },
          filters: this.showTypeList,
          filterMultiple: false
        },
        {
          dataIndex: 'relateType',
          title: '链接类型',
          filters: relateTypeList,
          filterMultiple: false,
          align: 'left',
          onExport: text => (relateTypeList.find(e => e.value == text) || { text: '' }).text,
          customRender: text => (relateTypeList.find(e => e.value == text) || { text: '' }).text
        },
        {
          dataIndex: 'sort',
          title: '排序',
          align: 'left',
          sorter: (a, b) => a.sort - b.sort
        },
        {
          dataIndex: 'endTime',
          title: '下架时间',
          align: 'left',
          sorter: (a, b) => moment(a.endTime).diff(moment(b.endTime))
        },
        {
          dataIndex: 'status',
          title: '状态',
          type: 'badge',
          yesText: '上架',
          noText: '下架',
          onExport: records => {
            return ['上架', '下架'][Number(records) - 1]
          },
          filters: [
            {
              text: '上架',
              value: '1'
            },
            {
              text: '下架',
              value: '2'
            }
          ],
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data === '1' ? '上架' : '下架',
              color: data === '1' ? 'green' : 'red'
            }
          }
        },
        {
          dataIndex: 'a8',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              //   {
              //     name: '二维码',
              //     onClick: () => {
              //       if (!records.jumpUrl)
              //         return this.$message.warning(
              //           '链接地址不存在，无法生成二维码'
              //         )

              //       apiTool.showModal({
              //         title: '二维码',
              //         view: QrCode,
              //         viewProps: {
              //           text: records.jumpUrl
              //         }
              //       })
              //     }
              //   },
              {
                name: records.status === '1' ? '下架' : '上架',
                onClick: () => {
                  api.command.editPost.call(this, {
                    url: `/market/farmBanner/${records.status === '1' ? 'down' : 'up'}?id=${records.id}`
                  })
                }
              },
              {
                name: '编辑',
                onClick: () => {
                  this.$router.push({
                    path: '/marketingCenter/detailBanner',
                    query: { id: records.id }
                  })
                }
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认删除？',
                onClick: () =>
                  api.command.delPost.call(this, {
                    url: `/market/farmBanner/delete?id=${records.id}`
                  })
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        // {
        //   name: '添加',
        //   viewType: 'menu',
        //   children: [
        //     {
        //       name: '活动',
        //       onClick: () => {
        //         this.$router.push('/marketingCenter/detailActivity')
        //       }
        //     },
        //     {
        //       name: 'Banner',
        //       onClick: () => this.$router.push('/marketingCenter/detailBanner')
        //     }
        //   ]
        // },
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/marketingCenter/detailBanner')
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.delPostArr.call(this, {
                  url: '/market/farmBanner/deleteBatch',
                  params: { idList }
                })
              }
            }
          ]
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
